import { Routes, Route, Navigate } from "react-router-dom";
import AdCopy from "../../components/ad-copy/ad-copy";
import { FunnelAnalytics } from "../../components/analytics";
import FunnelFormTabs from "../../components/funnel-form/layout";
import FunnelForm from "../../components/funnel-form/funnel-form";
import FunnelsTable from "../../components/funnels-table/funnels-table";
import FunnelFilters from "../../components/funnel-filters/funnel-filters";
import LeadserviceOverview from "../../components/funnel-leadservice-overview";
import FunnelIntegration from "../../components/funnel-integration/funnel-integration";
// import AdDuplicator from "../../components/ad-duplicator/ad-duplicator";

const FunnelPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<FunnelsTable />} />
        <Route path=":id" element={<FunnelFormTabs />}>
          <Route index path="form" element={<FunnelForm />} />
          <Route path="integration" element={<FunnelIntegration />} />
          <Route path="insight" element={<FunnelAnalytics />} />
          <Route path="filters" element={<FunnelFilters />} />
          <Route path="ad-copy" element={<AdCopy />} />
          <Route
            path="leadservice-overview"
            element={<LeadserviceOverview />}
          />
          {/* <Route path="ad-duplicator" element={<AdDuplicator />} /> */}
        </Route>
        <Route path="new" element={<FunnelFormTabs />}>
          <Route index element={<FunnelForm />} />
        </Route>
        <Route path="*" element={<Navigate to="/dashboard/funnels" />} />
      </Routes>
    </>
  );
};

export default FunnelPage;
