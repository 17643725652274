import {
  Link,
  Outlet,
  useParams,
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";
import styled from "styled-components";
import {
  faCheckCircle,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { faMeta, faHubspot } from "@fortawesome/free-brands-svg-icons";
import {
  FUNNEL_PAGES,
  FB_FORM_MODE,
  FUNNEL_DESIGNS,
  NESTED_SIDENAV,
  HUBSPOT_PROJECT_ID_LINK
} from "../strings";
import Loader from "../loader/loader";
import {
  getFunnelById,
  getFunnelVersionsById
} from "../../services/api-declaration";
import { FunnelTypeBadge } from "../common-styling";
import { showError } from "../../services/toast-service";
import NestedTabsComponent from "../nested-tabs-component";
import AbsoluteButtonAnchor from "./absolute-button-anchor";
import GoToSiteButton from "../go-to-site-button/site-button";
import { countryOptions } from "../common-confgs/common-vars";
import { handleTestMode } from "../../services/common-service";
import { isValidHttpUrl } from "../../services/common-service";
import CopyToClipboardButton from "../copy-to-clipboard-button";
import { generateSiteUrl } from "../../services/common-service";
import { setActiveIntegrationsCount } from "../../store/slices/integrationsInfo";
import { useTranslation } from "react-i18next";

const FunnelInfo = styled.div`
  background: var(--body-light-bg);
  border-bottom: 3px solid var(--primary-color);
`;

const FunnelFormTabs = () => {
  const { t } = useTranslation();
  const params = useParams();
  const isTabDisabled = Boolean(!params.id);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const funnelTypeParam = searchParams.get("funnelType");
  const dispatch = useDispatch();
  const activeIntegrationsCount = useSelector(
    (state) => state.integrationsInfo.count
  );
  const customNavigate = useRef(navigate);
  const [isLoading, setIsLoading] = useState(true);
  const [funnel, setFunnel] = useState();
  const [fbFormMode, setFbFormMode] = useState(FB_FORM_MODE.ADD_NEW);
  const [funnelVersions, setFunnelVersions] = useState();
  const initialFbTabInfo = {
    page: null,
    form: null
  };
  const [fbTabInfo, setFbTabInfo] = useState(initialFbTabInfo);
  const [leadsCount, setLeadsCount] = useState(0);

  let activeTab;
  if (params.id) {
    if (location.pathname.split(params.id)?.[1]?.split("/")?.length > 1) {
      activeTab = location.pathname.split(params.id)[1].split("/")[1];
    } else {
      activeTab = location.pathname.split(params.id)[1].split("/")[0];
    }
  } else {
    activeTab = "";
  }

  const handleFbFormMode = (mode) => setFbFormMode(mode);

  const resetFbTabInfo = () => setFbTabInfo(initialFbTabInfo);

  useEffect(() => {
    if (params.id) {
      const loadFunnel = async () => {
        try {
          setIsLoading(true);
          const response = await getFunnelById(params.id);
          const data = response.data.data;
          if (!data) {
            customNavigate.current("dashboard/funnels");
            return;
          }
          setLeadsCount(data.leadsCount);
          const versionResponse = await getFunnelVersionsById(params.id);
          const versions = versionResponse.data.data;
          versions.currentVersion = versions.allVersions.find(
            ({ _id }) => _id === versions.currentVersion.activeVersion
          );
          setFunnelVersions(versions);
          if (!data?.facebookForm?.user) {
            setFbFormMode(FB_FORM_MODE.ADD_NEW);
          } else {
            setFbFormMode(FB_FORM_MODE.READONLY);
            setFbTabInfo({
              page: data.facebookForm.page,
              form: data.facebookForm.forms
            });
          }
          let protocol = "http://";
          if (data.companyInfo?.companyWebsite.includes("http")) {
            protocol = isValidHttpUrl(data.companyInfo?.companyWebsite)
              ? `${new URL(data.companyInfo?.companyWebsite).protocol}//`
              : "";
          }

          const country = countryOptions.find(
            (country) => country._id === data?.companyInfo?.country
          );
          dispatch(setActiveIntegrationsCount(data.activeIntegrationsCount));
          setFunnel({
            customer: data.customer,
            category: data.category,
            general: data.general,
            filters: data.filters,
            funnelQA: data.funnelQA,
            outcomes: data.outcomes,
            companyInfo: {
              ...data.companyInfo,
              companyWebsite: data.companyInfo?.companyWebsite.replace(
                /(^\w+:|^)\/\//,
                ""
              ),
              country
            },
            domain: data.domain,
            subDomain: data.subDomain,
            textElements: data.textElements,
            design: data.design,
            thankyouPage: data.thankyouPage,
            companyWebsiteProtocol: protocol,
            facebookForm: {
              page: data?.facebookForm?.page || null,
              forms: data?.facebookForm?.forms?.length
                ? data?.facebookForm?.forms
                : null,
              user: data?.facebookForm?.user || null
            },
            scriptHead: data.scriptHead,
            scriptBody: data.scriptBody,
            scriptThankyou: data.scriptThankyou,
            activeVersion: data.activeVersion,
            leadService: data.leadService,
            leadServicePlus: data.leadServicePlus,
            emailToCustomer: data.emailToCustomer,
            customerAppointmentLink: data.customerAppointmentLink,
            leadPriceBenchmark: data.leadPriceBenchmark || 0,
            leadQualifiedPriceBenchmark: data.leadQualifiedPriceBenchmark || 0,
            companyNameForLeadAgent: data.companyNameForLeadAgent,
            leadServiceProductName: data.leadServiceProductName,
            leadServicePlusPriceType: data.leadServicePlusPriceType,
            pricePerInvoiceUnitForLeadAgent:
              data.pricePerInvoiceUnitForLeadAgent,
            fixedPricePerInvoiceUnitForLeadAgent:
              data.fixedPricePerInvoiceUnitForLeadAgent,
            nameOfAccountingUnitForLeadAgent:
              data.nameOfAccountingUnitForLeadAgent,
            alternativeAddress: data.alternativeAddress,
            hubspotProjectId: data.hubspotProjectId || "",
            funnelDesign: data.funnelDesign || FUNNEL_DESIGNS.CLASSIC,
            advertisingAccountLink: data.advertisingAccountLink,
            minBudgetQuery: data.minBudgetQuery,
            maxBudgetQuery: data.maxBudgetQuery,
            pricePerAreaBelowMinBudget: data.pricePerAreaBelowMinBudget,
            pricePerAreaBetweenBudget: data.pricePerAreaBetweenBudget,
            pricePerAreaAboveMaxBudget: data.pricePerAreaAboveMaxBudget
          });
        } catch (error) {
          if (
            error.response?.status === 422 ||
            error.response?.status === 404
          ) {
            customNavigate.current("..");
          }
          showError(
            error?.response?.data?.message ||
              t("toastMessage.errorInGetting", { what: "funnel" })
          );
        } finally {
          setIsLoading(false);
        }
      };
      loadFunnel();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [params.id]);

  const renderIntegrationTabTitle = () => {
    const flag = activeIntegrationsCount > 0;
    return (
      <div className="d-flex align-items-center">
        <div>Integration</div>
        {params.id && (
          <OverlayTrigger
            overlay={
              <Tooltip>
                {flag
                  ? t("funnelIntegration.tabTitleActiveTooltip")
                  : t("funnelIntegration.tabTitleNotIntegratedTooltip")}
              </Tooltip>
            }
          >
            <div className="ms-2">
              {flag ? (
                <FontAwesomeIcon
                  className="text-success"
                  icon={faCheckCircle}
                />
              ) : (
                <FontAwesomeIcon
                  className="text-danger"
                  icon={faExclamationCircle}
                />
              )}
            </div>
          </OverlayTrigger>
        )}
      </div>
    );
  };
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="d-flex h-100">
        <div className="sub-sidebar">
          <div className="inner-sub-sidebar">
            <div className="tab-heading ps-3 mt-4">Funnels</div>
            <NestedTabsComponent
              activeTab={activeTab}
              nestedNavType={NESTED_SIDENAV.FUNNEL}
              tabs={[
                { evKey: FUNNEL_PAGES.FUNNEL_FORM, title: t("common.form") },
                {
                  evKey: FUNNEL_PAGES.INTEGRATION_FORM,
                  title: renderIntegrationTabTitle(),
                  isDisabled: isTabDisabled
                },
                {
                  evKey: FUNNEL_PAGES.FUNNEL_INSIGHT,
                  title: "Insight",
                  isDisabled: isTabDisabled
                },
                {
                  evKey: FUNNEL_PAGES.FUNNEL_FILTERS,
                  title: "Filters",
                  isDisabled: isTabDisabled
                },
                {
                  evKey: FUNNEL_PAGES.AD_COPY,
                  title: t("adCopy.title"),
                  isDisabled: isTabDisabled
                },
                {
                  evKey: FUNNEL_PAGES.LS_OVERVIEW,
                  title: "Leadservice Overview",
                  isDisabled: isTabDisabled
                }
              ]}
            />
          </div>
        </div>
        <div className="outlet-wrapper">
          <FunnelInfo className="sticky-top d-flex align-items-center justify-content-between mt-2 px-2 py-3">
            <div className="d-flex">
              <h5 className="mb-0 me-4 d-flex">
                {params.id ? funnel?.general?.funnelName : t("createFunnel")}
              </h5>
              <FunnelTypeBadge
                className="d-flex align-items-center px-3"
                btnbg={`--${funnelTypeParam?.toLowerCase()}-badge-bg`}
                btncolor={`--${funnelTypeParam?.toLowerCase()}-color`}
              >
                {funnelTypeParam?.toLowerCase()}
              </FunnelTypeBadge>
            </div>
            {params.id && (
              <div>
                <CopyToClipboardButton
                  copyWhat="Domain"
                  linkToCopy={generateSiteUrl(
                    funnel?.domain,
                    funnel?.subDomain
                  )}
                  customClasses="me-2 pt-0"
                  customStyles={{ paddingBottom: 2 }}
                />
                <AbsoluteButtonAnchor
                  icon={faMeta}
                  link={funnel?.advertisingAccountLink}
                  isDisabled={!funnel?.advertisingAccountLink}
                  buttonText={t("openAdvertisingAccount")}
                  customButtonStyles={{ paddingBottom: 2 }}
                  classes="me-2 pt-0"
                />
                <AbsoluteButtonAnchor
                  icon={faHubspot}
                  link={
                    funnel?.hubspotProjectId
                      ? `${HUBSPOT_PROJECT_ID_LINK}/${funnel.hubspotProjectId}`
                      : void 0
                  }
                  isDisabled={!funnel?.hubspotProjectId}
                  buttonText={t("openProject")}
                  customButtonStyles={{
                    paddingBottom: 2,
                    border: "1px solid transparent",
                    background: "var(--hubspot-btn-bg)"
                  }}
                  classes="me-2 pt-0"
                />
                <Button
                  as={Link}
                  target="_blank"
                  className="me-2 pt-0"
                  style={{ paddingBottom: 2 }}
                  variant="primary"
                  to={`../../leads?funnel=${params.id}`}
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-bold me-1">{leadsCount}</div>
                    <div>Leads</div>
                  </div>
                </Button>
                {/* <span className="me-2">
                  <GoToSiteButton
                    url={generateSiteUrl(funnel?.domain, funnel?.subDomain)}
                    funnelId={params.id}
                  />
                </span> */}
                <GoToSiteButton
                  url={handleTestMode(
                    generateSiteUrl(funnel?.domain, funnel?.subDomain)
                  )}
                  funnelId={params.id}
                  isTestMode
                />
              </div>
            )}
          </FunnelInfo>
          <Outlet
            context={{
              isLoading,
              funnel,
              funnelVersions,
              setFunnelVersions,
              setFunnel,
              fbFormMode,
              handleFbFormMode,
              fbTabInfo,
              resetFbTabInfo,
              funnelType: funnel?.general?.funnelType
            }}
          />
        </div>
      </div>
    </>
  );
};
export default FunnelFormTabs;
