import { Tab, Tabs } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import {
  SETTINGS,
  FUNNEL_PAGES,
  NESTED_SIDENAV,
  CUSTOMER_ROUTES,
  EVALUATION_TYPE,
  MISSING_INFO_TYPE,
  CREATIVE_DASHBOARD_TABS
} from "../strings";
import styled from "styled-components";

const navigations = {
  [NESTED_SIDENAV.FUNNEL]: {
    [FUNNEL_PAGES.FUNNEL_FORM]: "form",
    [FUNNEL_PAGES.INTEGRATION_FORM]: "integration",
    [FUNNEL_PAGES.FUNNEL_INSIGHT]: "insight",
    [FUNNEL_PAGES.FUNNEL_FILTERS]: "filters",
    [FUNNEL_PAGES.AD_COPY]: "ad-copy",
    [FUNNEL_PAGES.LS_OVERVIEW]: "leadservice-overview"
    // [FUNNEL_PAGES.AD_DUPLICATOR]: "ad-duplicator"
  },
  [NESTED_SIDENAV.CUSTOMER]: {
    [CUSTOMER_ROUTES.OVERVIEW]: "overview",
    [CUSTOMER_ROUTES.FUNNELS]: "funnels",
    [CUSTOMER_ROUTES.LEADS]: "leads",
    [CUSTOMER_ROUTES.PERFORMANCE_REPORT]: "performance"
  },
  [NESTED_SIDENAV.SETTING]: {
    [SETTINGS.PROFILE]: "profile",
    [SETTINGS.SECURITY]: "security",
    [SETTINGS.CHANGE_PASSWORD]: "change-password"
  },
  [NESTED_SIDENAV.EVALUATION]: {
    [EVALUATION_TYPE.OVERVIEW]: "overview?dateRange=L14D",
    [EVALUATION_TYPE.INSIGHTS]: "insights",
    [EVALUATION_TYPE.OPTIMIZATION]: "optimization"
  },
  [NESTED_SIDENAV.ADS_EVALUATION]: {
    [CREATIVE_DASHBOARD_TABS.OVERVIEW]: "overview",
    [CREATIVE_DASHBOARD_TABS.SUGGESTION_GALLERY]: "suggestion-gallery",
    [CREATIVE_DASHBOARD_TABS.FUNNEL_OVERVIEW]:
      "funnel-overview?dateRange=BT_L14D"
  },
  [NESTED_SIDENAV.MISSING_INFO]: {
    [MISSING_INFO_TYPE.LEAD_INFO]: "lead",
    [MISSING_INFO_TYPE.CATEGORY_INFO]: "category",
    [MISSING_INFO_TYPE.CUSTOMER_INFO]: "customer",
    [MISSING_INFO_TYPE.HUBSPOT_INFO]: "hubspot",
    [MISSING_INFO_TYPE.AD_ACCOUNT]: "ad-account",
    [MISSING_INFO_TYPE.UTM_PARAMETER]: "utm-parameter"
  }
};

const BubbleSpan = styled.span`
  width: 9px;
  height: 9px;
  border-radius: 5px;
  background: #fd7e14;
  margin-left: 4px;
  margin-top: -8px;
`;

const NestedTabsComponent = ({
  activeTab = null,
  nestedNavType,
  tabs,
  clearParams
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const activeTabName = activeTab || location.pathname.split("/").pop();

  const getKeyByValue = (_object, activeVal) => {
    const activeItem = Object.keys(_object).find((key) => {
      if (_object[key].includes("?")) {
        return _object[key].split("?")[0] === activeVal;
      } else {
        return _object[key] === activeVal;
      }
    });
    return activeItem;
  };

  const handleOnSelect = (evKey) => {
    if (clearParams) {
      for (const [key] of searchParams.entries()) {
        searchParams.delete(key);
      }
    }
    navigate({
      pathname: navigations[nestedNavType][evKey],
      search: searchParams.toString()
    });
  };

  return (
    <Tabs
      fill="true"
      variant="pills"
      className="tab-container"
      onSelect={(evKey) => handleOnSelect(evKey)}
      activeKey={getKeyByValue(navigations[nestedNavType], activeTabName)}
    >
      {tabs.map((tab, tabIdx) => (
        <Tab
          key={tabIdx}
          title={
            <>
              {tab.title}
              {tab.popUp && <BubbleSpan />}
            </>
          }
          eventKey={tab.evKey}
          tabClassName="tab-item"
          disabled={tab.isDisabled}
        />
      ))}
    </Tabs>
  );
};
export default NestedTabsComponent;
