import styled from "styled-components";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import Sidebar from "../sidebar";
import Header from "../header/Header";
import {
  generateSystemIssueText,
  generateSystemActionText
} from "../../services/common-service";
import { USER_ROLES } from "../strings";
import {
  getNonRejectedRawHookData,
  getLatestSystemHealthError
} from "../../services/api-declaration";
import { showError } from "../../services/toast-service";

const ErrorNotificationBanner = styled.div`
  background: red;
  padding: 2px;
  padding-left: 20px;
  color: white;
  font-size: 12px;
  font-weight: bold;
`;

const DashboardLayout = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const [hasAnyNonRejectedRawData, setHasAnyNonRejectedRawData] =
    useState(false);
  const [systemHealthData, setSystemHealthData] = useState(null);

  const fetchFbAdLeadError = async () => {
    try {
      const sysHealthRes = await getLatestSystemHealthError();
      setSystemHealthData(sysHealthRes.data.data[0]);
      if (!sysHealthRes.data.data.length) {
        try {
          const nonRejectedFbDataRes = await getNonRejectedRawHookData();
          if (nonRejectedFbDataRes.data.data.length) {
            setHasAnyNonRejectedRawData(true);
          }
        } catch (error) {
          console.log("Error in fetch fb hook raw data", error);
          showError("Error in fetch fb hook raw data!");
        }
      }
    } catch (error) {
      console.log("Error in fetch system health alert", error);
      showError("Error in fetch system health alert!");
    }
  };
  const fbAdLeadErrorCb = useCallback(fetchFbAdLeadError, []);
  useEffect(() => {
    fbAdLeadErrorCb();
  }, [fbAdLeadErrorCb]);

  return (
    <>
      {auth.user.role === USER_ROLES.ADMIN &&
        auth.user.workspace.noValidPrimaryTokenExists && (
          <ErrorNotificationBanner>
            Primary Facebook Access Token is not valid anymore need action!
          </ErrorNotificationBanner>
        )}
      {systemHealthData && (
        <ErrorNotificationBanner>
          Alert! {generateSystemIssueText(systemHealthData.issue)} error
          occurred in {generateSystemActionText(systemHealthData.action)}.
        </ErrorNotificationBanner>
      )}
      {hasAnyNonRejectedRawData && (
        <ErrorNotificationBanner>
          Alert! There are some non created facebook ad leads in raw hook data.
        </ErrorNotificationBanner>
      )}
      <Header />
      <div className="d-flex bg-white">
        {auth.user.role === USER_ROLES.ADMIN && <Sidebar />}
        <div
          className={`main-content ${
            auth.user.role === USER_ROLES.AGENT &&
            "rounded-0 w-100 mw-100 h-100"
          }`}
        >
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-sm-12 px-3">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
