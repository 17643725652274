import moment from "moment";
import { FUNNEL_PERFORMANCE_DURATION_FILTER } from "../strings";

export const PICKER_LAYOUT = {
  TABS: "TABS",
  DROPDOWN: "DROPDOWN"
};

export const DATE_FILTERS = {
  ALL_TIME: "All time",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  L7D: "Last 7 days",
  L14D: "Last 14 days",
  L30D: "Last 30 days",
  L90D: "Last 90 days",
  RANGE: "Custom range",
  /////////////
  YEAR: "Year",
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month"
};

const { WEEK, FORTNIGHT, MONTH } = FUNNEL_PERFORMANCE_DURATION_FILTER;

const DATE_FILTER_API_KEYS = {
  [DATE_FILTERS.TODAY]: "TD",
  [DATE_FILTERS.DAY]: undefined,
  [DATE_FILTERS.YESTERDAY]: "YD",
  [DATE_FILTERS.L7D]: WEEK,
  [DATE_FILTERS.L14D]: FORTNIGHT,
  [DATE_FILTERS.L30D]: MONTH,
  [DATE_FILTERS.L90D]: "BT_L90D",
  [DATE_FILTERS.RANGE]: undefined
};

export const PICKER_OPT_A = [
  { name: DATE_FILTERS.TODAY },
  { name: DATE_FILTERS.DAY },
  { name: DATE_FILTERS.YESTERDAY },
  { name: DATE_FILTERS.L7D },
  { name: DATE_FILTERS.L14D },
  { name: DATE_FILTERS.L30D },
  { name: DATE_FILTERS.RANGE }
];

export const PICKER_OPT_B = [
  { name: DATE_FILTERS.ALL_TIME },
  { name: DATE_FILTERS.DAY },
  { name: DATE_FILTERS.WEEK },
  { name: DATE_FILTERS.MONTH },
  { name: DATE_FILTERS.YEAR },
  { name: DATE_FILTERS.RANGE }
];

export const PICKER_OPT_AD_CREATIVE = [
  { name: DATE_FILTERS.DAY },
  { name: DATE_FILTERS.YESTERDAY },
  { name: DATE_FILTERS.L7D },
  { name: DATE_FILTERS.L14D },
  { name: DATE_FILTERS.L30D },
  { name: DATE_FILTERS.L90D },
  { name: DATE_FILTERS.RANGE }
];

export const showDatePicker = (selectedOption) => {
  switch (selectedOption) {
    case DATE_FILTERS.TODAY:
    case DATE_FILTERS.ALL_TIME:
    case DATE_FILTERS.YESTERDAY:
    case DATE_FILTERS.L7D:
    case DATE_FILTERS.L14D:
    case DATE_FILTERS.L30D:
    case DATE_FILTERS.L90D:
      return false;
    default:
      return true;
  }
};

export const dateRangeGenerator = (selectedValue, date) => {
  const today = moment().startOf("day");
  let startDate = moment(date).startOf("day");
  let endDate = moment(date).endOf("day");
  switch (selectedValue) {
    case DATE_FILTERS.DAY:
      break;
    case DATE_FILTERS.TODAY:
      break;
    case DATE_FILTERS.YESTERDAY:
      startDate = moment(today).subtract(1, "days").startOf("day");
      endDate = moment(today).subtract(1, "days").endOf("day");
      break;
    case DATE_FILTERS.L7D:
      startDate = moment(today).subtract(6, "days").startOf("day");
      break;
    case DATE_FILTERS.WEEK:
      startDate = moment(date).startOf("week");
      endDate = moment(date).endOf("week");
      break;
    case DATE_FILTERS.L14D:
      startDate = moment(today).subtract(13, "days").startOf("day");
      break;
    case DATE_FILTERS.L30D:
      startDate = moment(today).subtract(29, "days").startOf("day");
      break;
    case DATE_FILTERS.MONTH:
      startDate = moment(date).startOf("month");
      endDate = moment(date).endOf("month");
      break;
    case DATE_FILTERS.YEAR:
      startDate = moment(date).startOf("year");
      endDate = moment(date).endOf("year");
      break;
    default:
      startDate = undefined;
      endDate = undefined;
      break;
  }

  return { startDate: startDate?.toDate(), endDate: endDate?.toDate() };
};

export const adCreativeApiDateRange = ({
  selectedDateFilter,
  startDate,
  endDate
}) => {
  let dateRange = DATE_FILTER_API_KEYS[selectedDateFilter];
  let from;
  let to;
  switch (selectedDateFilter) {
    case DATE_FILTERS.DAY:
    case DATE_FILTERS.RANGE:
      from = moment(startDate).format("YYYY-MM-DD");
      to = moment(endDate).format("YYYY-MM-DD");
      break;

    case DATE_FILTERS.TODAY:
    case DATE_FILTERS.YESTERDAY:
    case DATE_FILTERS.L7D:
    case DATE_FILTERS.L14D:
    case DATE_FILTERS.L30D:
    default:
      from = undefined;
      to = undefined;
  }

  return {
    dateRange,
    from,
    to
  };
};
