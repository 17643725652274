import styled from "styled-components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Card, Button, Container } from "react-bootstrap";
import PDFViewer from "./pdf-viewer";
import { SCRIPT_ROUTE } from "../strings";
import { setMe } from "../../store/slices/auth";
import UploadScriptDialog from "./upload-script-dialog";
import { showSuccess } from "../../services/toast-service";
import { CustomCard, PageHeading } from "../common-styling";
import SpinnerButton from "../spinner-button/spinner-button";
import LeadserviceOverview from "../funnel-leadservice-overview";
import { generateScriptUrl } from "../../services/common-service";
import { updateLeadBlockTime } from "../../services/api-declaration";
import { useTranslation } from "react-i18next";

const PlusSpan = styled.span`
  font-size: 20px;
`;

const LeadservicePanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [loadingLeadBlock, setLoadingLeadBlock] = useState(false);
  const [availableAfter, setAvailableAfter] = useState();
  const [showScriptDialog, setShowScriptDialog] = useState(false);
  const [scriptTitle, setScriptTitle] = useState("");

  const toggleUploadDialog = () => setShowScriptDialog(!showScriptDialog);

  const onEditClick = (title) => {
    toggleUploadDialog();
    setScriptTitle(title);
  };

  const fetchAvailableAfter = async () => {
    try {
      const resp = localStorage.getItem("userData");
      if (resp) {
        const data = JSON.parse(resp).workspace.availableAfterDelay;
        setAvailableAfter(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateLeadBlockTimeFunc = async () => {
    try {
      setLoadingLeadBlock(true);
      const resp = await updateLeadBlockTime({ availableAfter });
      if (resp.data.data) {
        dispatch(
          setMe({
            userData: {
              ...user,
              workspace: {
                ...user.workspace,
                availableAfterDelay: availableAfter
              }
            }
          })
        );
        showSuccess(resp.data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingLeadBlock(false);
    }
  };

  useEffect(() => {
    fetchAvailableAfter();
  }, []);

  return (
    <div>
      <PageHeading>Leadservice Panel</PageHeading>

      <Container fluid className="mt-4 px-0">
        <Form.Group controlId="available-after-delay">
          <Form.Label>{t("availableAfter")}</Form.Label>
          <div className="d-flex gap-2">
            <Form.Control
              type="number"
              className="w-25"
              placeholder={`Enter ${t("availableAfter")}`}
              // defaultValue={availableAfter}
              value={availableAfter}
              onChange={(e) => setAvailableAfter(e.target.value)}
            />
            {loadingLeadBlock ? (
              <SpinnerButton btnText={t("common.save")} />
            ) : (
              <Button
                disabled={loadingLeadBlock}
                onClick={updateLeadBlockTimeFunc}
              >
                {t("common.save")}
              </Button>
            )}
          </div>
        </Form.Group>

        <LeadserviceOverview />

        <Row className="mb-3">
          <Col md={12} lg={6}>
            <CustomCard>
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div className="fw-bold">Leadservice {t("script")}</div>
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => onEditClick(SCRIPT_ROUTE.LS)}
                >
                  {t("common.change")}
                </Button>
              </Card.Header>
              <div className="p-3">
                <PDFViewer
                  ariaLabel="default"
                  scriptUrl={generateScriptUrl({
                    workspace: user.workspace,
                    scriptProp: "leadServiceScript",
                    localFileName: "default.pdf"
                  })}
                />
              </div>
            </CustomCard>
          </Col>
          <Col md={12} lg={6}>
            <CustomCard>
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div className="fw-bold">
                  Leadservice<PlusSpan>+</PlusSpan> {t("script")}
                </div>
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => onEditClick(SCRIPT_ROUTE.LS_PLUS)}
                >
                  {t("common.change")}
                </Button>
              </Card.Header>
              <div className="p-3">
                <PDFViewer
                  ariaLabel="ls-plus"
                  scriptUrl={generateScriptUrl({
                    workspace: user.workspace,
                    scriptProp: "leadServicePlusScript",
                    localFileName: "leadservice_plus.pdf"
                  })}
                />
              </div>
            </CustomCard>
          </Col>
        </Row>
        <UploadScriptDialog
          title={scriptTitle}
          show={showScriptDialog}
          toggle={toggleUploadDialog}
        />
      </Container>
    </div>
  );
};
export default LeadservicePanel;
