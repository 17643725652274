import styled from "styled-components";
import { useState, useEffect } from "react";
import {
  faPhoneFlip,
  faCalendarDays,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCallInsights,
  getLeadSyncRate,
  getOnHoldLeadsCount
} from "../../services/api-declaration";
import BarChart from "../charts/barChart";
import {
  DATE_FILTERS,
  PICKER_OPT_A,
  PICKER_LAYOUT
} from "../date-range-pickers/picker-helper";
import { CustomCard } from "../common-styling";
import StackedBarChart from "../charts/stackedBarChart";
import { showError } from "../../services/toast-service";
import DateSelectorToolbar from "../date-range-pickers/date-selector-toolbar";
import { useTranslation } from "react-i18next";

const StyledIconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  padding: 2px;
`;

const LeadserviceOverview = () => {
  const { t } = useTranslation();
  const params = useParams();
  const outletContext = useOutletContext();
  const [callInsights, setCallInsights] = useState();
  const [leadInsights, setLeadInsights] = useState();
  const [onHoldLeadsCount, setOnHoldLeadsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [callInsightsLoading, setCallInsightsLoading] = useState(false);

  const fetchOnHoldLeadsCount = async (funnelId) => {
    try {
      const response = await getOnHoldLeadsCount({ funnelId });
      setOnHoldLeadsCount(response.data.data);
    } catch (err) {
      console.log(err);
      showError(err.response?.data?.message);
    }
  };
  const fetchCallInsights = async (funnelId) => {
    setCallInsightsLoading(true);
    try {
      const resp = await getCallInsights({ funnelId });
      if (resp) {
        const closedLeadsAverage =
          resp.data.data.closedLeadsResult.totalContactAttempts /
          resp.data.data.closedLeadsResult.totalCount;
        setCallInsights({
          openLeads: resp.data.data.contactAttemptsArray,
          closedLeadsAverage: Math.round(closedLeadsAverage * 100) / 100
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCallInsightsLoading(false);
    }
  };

  const fetchLeadSyncRate = async (queryParams) => {
    if (loading) return;
    setLoading(true);
    try {
      const resp = await getLeadSyncRate(queryParams);
      if (resp) {
        setLeadInsights(resp.data.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const fetchData = ({ startDate, endDate }) => {
    fetchLeadSyncRate({
      startDate,
      endDate,
      funnelId: params.id
    });
  };

  useEffect(() => {
    const funnelId = params.id;
    fetchCallInsights(funnelId);
    fetchOnHoldLeadsCount(funnelId);
  }, [params]);

  return (
    <div>
      {params.id &&
        outletContext?.funnel &&
        !outletContext.funnel.leadService && (
          <div className="px-2 py-3 bg-warning fw-bold">
            <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
            {t("leadservicePanel.currentlyDisabledWarning")}
          </div>
        )}
      <Row className="my-4">
        <Col md={8} sm={12}>
          <CustomCard className="h-100">
            <Card.Header className="mb-3">
              <div className="fw-bold">{t("leadpannel.leadCountTitle")}</div>
            </Card.Header>
            <div className="h-100">
              {callInsightsLoading && (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <Spinner animation="border" />
                </div>
              )}
              {callInsights?.openLeads?.length ? (
                <StackedBarChart
                  series={[
                    {
                      data: callInsights.openLeads.map(
                        (data) =>
                          data.leadCount -
                          data.availableAfterCount -
                          data.contactLaterCount
                      ),
                      name: "offene lead"
                    },
                    {
                      data: callInsights.openLeads.map(
                        (data) => data.availableAfterCount
                      ),
                      name: "verfügbar nach",
                      color: "#808080"
                    },
                    {
                      data: callInsights.openLeads.map(
                        (data) => data.contactLaterCount
                      ),
                      name: "Offene Anfrage",
                      color: "#808080"
                    }
                  ]}
                  categories={callInsights.openLeads.map(
                    (data) => `${data.contactAttempts}.${t("contact")}`
                  )}
                />
              ) : (
                <h6 className="h-100 fw-bold d-flex align-items-center justify-content-center">
                  {t("noData")}
                </h6>
              )}
            </div>
          </CustomCard>
        </Col>
        <Col md={4} sm={12} className="d-flex flex-column gap-2">
          <div>
            <CustomCard>
              <Card.Header>
                <div className="fw-bold">{t("averageCalls")}</div>
              </Card.Header>
              {callInsightsLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: 70 }}
                >
                  <Spinner animation="border" />
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center px-4 py-3">
                  <div>
                    <div className="fw-bolder fs-5">
                      {callInsights?.closedLeadsAverage
                        ? callInsights?.closedLeadsAverage
                        : 0}
                    </div>
                    <div>{t("averageCalls")}</div>
                  </div>
                  <StyledIconDiv className="bg-primary">
                    <FontAwesomeIcon icon={faPhoneFlip} color={"white"} />
                  </StyledIconDiv>
                </div>
              )}
            </CustomCard>
          </div>
          <div>
            <CustomCard>
              <Card.Header>
                <div className="fw-bold">
                  {t("leadservicePanel.onHoldHeading")}
                </div>
              </Card.Header>
              <div className="d-flex justify-content-between align-items-center px-4 py-3">
                <div>
                  <div className="fw-bolder fs-5">{onHoldLeadsCount || 0}</div>
                  <div>{t("leadservicePanel.onHoldInfoMsg")}</div>
                </div>
                <StyledIconDiv className="bg-primary">
                  <FontAwesomeIcon icon={faCalendarDays} color={"white"} />
                </StyledIconDiv>
              </div>
            </CustomCard>
          </div>
          <div>
            <CustomCard>
              <Card.Header className="mb-2 py-1 d-flex justify-content-between">
                <div className="fw-bold align-self-center">
                  {t("leadpannel.leadRateTitle")}
                </div>
                <div>
                  <DateSelectorToolbar
                    options={PICKER_OPT_A}
                    fetchData={fetchData}
                    layout={PICKER_LAYOUT.DROPDOWN}
                    defaultSelected={DATE_FILTERS.TODAY}
                  />
                </div>
              </Card.Header>

              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: 200 }}
                >
                  <Spinner animation="border" />
                </div>
              ) : (
                <BarChart
                  columnWidth={"20%"}
                  isStrokeVisible
                  series={[
                    {
                      data: [leadInsights?.totalLeadCount],
                      name: t("leadpannel.totalLeads")
                    },
                    {
                      data: [leadInsights?.totalSyncCount],
                      name: t("leadpannel.syncedLeads")
                    }
                  ]}
                  categories={[
                    `${t("conversionRate")}: ${
                      leadInsights?.rate ? leadInsights?.rate : 0
                    }%`
                  ]}
                />
              )}
            </CustomCard>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default LeadserviceOverview;
