import React, { useCallback, useState, useEffect } from "react";
import TableLoader from "../loader/table-loader";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchBlockedPostalCodes } from "../../services/api-declaration";
import {
  CustomCard,
  HeaderColumn,
  BootstrapTable,
  FirstHeaderColumn,
  TableRow,
  TableDetailCell
} from "../common-styling";
import { generateDateTime } from "../../services/common-service";

const BlockedPostalCode = () => {
  const { t } = useTranslation();
  const [postalCodeData, setpostalCodeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const funnelId = useParams().id;
  const getBlockedPostalCodes = async (funnelId) => {
    try {
      const response = await fetchBlockedPostalCodes(funnelId);
      setpostalCodeData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getBlockedPostalCodesCb = useCallback(
    (funnelId) => getBlockedPostalCodes(funnelId),
    []
  );
  useEffect(() => {
    getBlockedPostalCodesCb(funnelId);
  }, [getBlockedPostalCodesCb, funnelId]);

  return (
    <div>
      <div className="mt-3">
        <CustomCard className="mb-3 p-3 rounded-0">
          <BootstrapTable responsive>
            <thead>
              <tr>
                <FirstHeaderColumn className="align-middle text-center">
                  {t("common.postalCode")}
                </FirstHeaderColumn>
                <HeaderColumn className="align-middle text-center">
                  {t("common.blockedOn")}
                </HeaderColumn>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoader colSpan={8} />
              ) : (
                postalCodeData.map((item, index) => (
                  <TableRow key={index}>
                    <TableDetailCell className="py-3 align-middle text-center fw-bold">
                      {item.leadData.leadDetails.leadZip}
                    </TableDetailCell>

                    <TableDetailCell className="py-3 align-middle text-center fw-bold">
                      {generateDateTime(item.createdAt)}
                    </TableDetailCell>
                  </TableRow>
                ))
              )}
            </tbody>
          </BootstrapTable>
        </CustomCard>
      </div>
    </div>
  );
};
export default BlockedPostalCode;
