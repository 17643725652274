import { useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { CustomCard } from "../common-styling";
import { useTranslation } from "react-i18next";
import { Button, Card, Collapse, Spinner } from "react-bootstrap";
import "./funnel-evaluation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { showError } from "../../services/toast-service";
import {
  getAllFunnelsEvaluation,
  // getAllCampaignsEvaluation,
  getAllAdsetsEvaluation,
  getAllAdsEvaluation
} from "../../services/api-declaration";
import Loader from "../loader/loader";
import CustomPagination from "../pagination/custom-pagination";
import AdvancedFilters from "../table-filter/advanced-filter";
import { FEFilterConfig } from "../funnels-table/filter-config";
import {
  getCurrencyData,
  getFormattedNumber
} from "../../services/common-service";
import FunnelLinksDialog from "./funnel-links-dialog";
// import moment from "moment";

const RowWrapper = styled.div`
  background-color: ${({ bold }) =>
    `${bold ? "rgb(94 112 130 / 80%)" : "#fff"}`};
  color: ${({ bold }) => `${bold ? "#fff" : "var(--secondary-text);"}`};
  border-bottom: ${({ bold }) => `${bold ? "2" : "1"}px solid #c0c7cf`};
  border-left: ${({ bold }) => `${bold ? "2" : "1"}px solid #c0c7cf`};
  border-right: ${({ bold }) => `${bold ? "2" : "1"}px solid #c0c7cf`};
  padding: 10px;
  min-width: 100%;
`;

const ParentRow = styled.div`
  min-width: 100%;
  display: inline-flex;
  flex-direction: column;
`;

const RowF = styled.div`
  border-bottom: ${({ bold }) => `${bold ? "0" : "1"}px solid #c0c7cf`};
  border-left: ${({ bold }) => `${bold ? "2" : "1"}px solid #c0c7cf`};
  border-right: ${({ bold }) => `${bold ? "2" : "1"}px solid #c0c7cf`};
  border-top: ${({ bold }) => `${bold ? "2" : "0"}px solid #c0c7cf`};

  background-color: ${({ bold }) =>
    `${bold ? "rgb(94 112 130 / 80%)" : "#fff"}`};
  color: ${({ bold }) => `${bold ? "#fff" : "var(--secondary-text);"}`};
  min-width: 100%;

  display: inline-flex;
  font-weight: 600;
  //color: var(--secondary-text);
  //background-color: #fff;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 8px;
`;

const RowH = styled.div`
  // width: 100%;
  // overflow-x: auto;
  // flex-flow: nowrap;

  min-width: 100%;
  display: inline-flex;
  border: 1px solid #c0c7cf;
  border-radius: 5px 5px 0px 0px;
  background-color: #f4f4f4 !important;
  font-weight: 600;
  color: var(--secondary-text);
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 8px;
`;

const ColumnF = styled.div`
  padding-right: 8px;
  padding-left: 8px;
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
`;

const FirstColumn = styled.div`
  padding-right: 8px;
  padding-left: 8px;
  min-width: 100px;
  max-width: 100px;
`;

const ViewFunnelsBtn = styled(Button)`
  color: inherit;
`;

const EvaluationOverview = () => {
  const defaultLoadingState = {
    // funnel: true,
    campaign: true,
    adset: true,
    ad: true
  };
  const defaultActiveRowState = {
    // funnel: false,
    campaign: false,
    adset: false,
    ad: false
  };
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  //  ---------- States and Refs
  // const [FunnelEvalData, setFunnelEvalData] = useState();
  const [campaignEvalData, setCampaignEvalData] = useState();
  const [adsetEvalData, setAdsetEvalData] = useState();
  const [adEvalData, setAdEvalData] = useState();
  const [paginateOpts, setPaginateOpt] = useState({});
  const [isLoading, setIsLoading] = useState(defaultLoadingState);
  const [activeRow, setActiveRow] = useState(defaultActiveRowState);
  const [showFunnelsModal, setShowFunnelsModal] = useState(false);
  const [campaignFunnels, setCampaignFunnels] = useState([]);

  //  ---------- Other Function
  const toggleModal = () => setShowFunnelsModal(!showFunnelsModal);
  const handleClickViewFunnels = (funnels) => {
    setCampaignFunnels(funnels);
    toggleModal();
  };
  const saveIt = (row, property, val) => {
    let newRow = { ...row };
    // if (property === "funnel") {
    //   newRow["campaign"] = -1;
    //   newRow["adset"] = -1;
    // }
    if (property === "campaign") {
      newRow["adset"] = -1;
    }
    if (newRow[property] === val) {
      newRow[property] = -1;
    } else {
      newRow[property] = val;
    }
    return newRow;
  };

  const fetchDataOnClick = (key, rowIdx, cbFun) => {
    setActiveRow((row) => saveIt(row, key, rowIdx));
    if (cbFun) {
      let loadingObj = { ...isLoading };
      // if (key === "funnel") {
      //   loadingObj = { ...defaultLoadingState, funnel: false };
      //   setCampaignEvalData(undefined);
      //   setAdsetEvalData(undefined);
      // }
      if (key === "campaign") {
        loadingObj = { ...loadingObj, adset: true };
        setAdsetEvalData(undefined);
      }
      loadingObj.ad = true;
      setIsLoading(loadingObj);
      setAdEvalData(undefined);
      setTimeout(() => {
        cbFun(rowIdx);
      }, 500);
    }
  };

  const getParamsData = () => {
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  };

  const getFunnelsEvaluation = async () => {
    setIsLoading(defaultLoadingState);
    setActiveRow(defaultActiveRowState);
    setAdsetEvalData(undefined);
    setAdEvalData(undefined);
    try {
      const paramsData = getParamsData();
      const params = { ...paramsData };
      const response = await getAllFunnelsEvaluation(params);
      const respData = response.data.data;
      if (!respData.data.length) {
        setPaginateOpt({});
        setCampaignEvalData([]);
        return;
      }

      setCampaignEvalData(respData.data);
      setPaginateOpt({
        totalPages: Math.ceil(
          respData.metadata.total / respData.metadata.limit
        ),
        hasPrevPage: respData.metadata.hasPrevPage,
        hasNextPage: respData.metadata.hasNextPage,
        totalDocs: respData.metadata.total,
        rowsOnPage: respData.metadata.length
      });
    } catch (error) {
      setPaginateOpt({});
      setCampaignEvalData(undefined);
      console.log("ERROR: ", error);
      showError(
        error.response?.data.message ||
          t("toastMessage.errorInGetting", { what: "Campaigns" })
      );
    } finally {
      setIsLoading((f) => ({ ...f, campaign: false }));
    }
  };

  // const getCampaignsEvaluation = async (funnelId) => {
  //   try {
  //     const params = getParamsData();
  //     const response = await getAllCampaignsEvaluation(funnelId, params);
  //     setCampaignEvalData(response.data.data);
  //   } catch (error) {
  //     console.log("ERROR: ", error);
  //     showError(
  //       error.response?.data.message ||
  //         t("toastMessage.errorInGetting", { what: "campaigns" })
  //     );
  //   } finally {
  //     setIsLoading((f) => ({ ...f, campaign: false }));
  //   }
  // };

  const getAdsetsEvaluation = async (campaignId) => {
    setIsLoading((f) => ({ ...f, adset: true, ad: true }));
    try {
      const params = getParamsData();
      const response = await getAllAdsetsEvaluation(
        // activeRow.funnel,
        campaignId,
        params
      );
      setAdsetEvalData(response.data.data);
    } catch (error) {
      console.log("ERROR: ", error);
      showError(
        error.response?.data.message ||
          t("toastMessage.errorInGetting", { what: "adsets" })
      );
    } finally {
      setIsLoading((f) => ({ ...f, adset: false }));
    }
  };

  const getAdsEvaluation = async (adsetId) => {
    setIsLoading((f) => ({ ...f, ad: true }));
    try {
      const params = getParamsData();
      const response = await getAllAdsEvaluation(
        // activeRow.funnel,
        activeRow.campaign,
        adsetId,
        params
      );
      setAdEvalData(response.data.data);
    } catch (error) {
      console.log("ERROR: ", error);
      showError(
        error.response?.data.message ||
          t("toastMessage.errorInGetting", { what: "ads" })
      );
    } finally {
      setIsLoading((f) => ({ ...f, ad: false }));
    }
  };

  const transformData = (data, name, isCampaign) => {
    const newData = [
      { data: getFormattedNumber(data.leadCount), width: 100 },
      { data: getCurrencyData(data.account_currency, data.spend), width: 100 },
      { data: getCurrencyData(data.account_currency, data.cpc), width: 100 },
      { data: getCurrencyData(data.account_currency, data.cpl), width: 100 },
      {
        data: !data.cpql
          ? "-"
          : getCurrencyData(data.account_currency, data.cpql),
        width: 100
      },
      {
        data: !data.leadQualifiedCount
          ? "-"
          : getFormattedNumber(data.leadQualifiedCount),
        width: 100
      },
      { data: getFormattedNumber(data.clicks), width: 100 },
      { data: getFormattedNumber(data.unique_clicks), width: 100 },
      { data: getFormattedNumber(data.reach), width: 100 },
      { data: getFormattedNumber(data.impressions), width: 100 }
      // `${moment(data.date_start).format("ll")} -
      // ${moment(data.date_stop).format("ll")}`
    ];
    if (isCampaign) {
      newData.unshift({
        data: data.category_name ? data.category_name : "-",
        width: 180
      });
      newData.unshift({
        data: data.customer_name ? data.customer_name : "-",
        width: 200
      });
      newData.push({
        data: data.funnelData?.length ? (
          <ViewFunnelsBtn
            size="sm"
            variant="outline-secondary"
            onClick={() => handleClickViewFunnels(data.funnelData)}
          >
            View Funnels
          </ViewFunnelsBtn>
        ) : (
          "-"
        ),
        width: 150
      });
    }
    newData.unshift({ data: name, width: 200 });
    return newData;
  };

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };

  //  ---------- CallBacks

  const getFunnelsCb = useCallback(
    getFunnelsEvaluation,
    // eslint-disable-next-line
    [searchParams]
  );

  //  ---------- UseEffects

  useEffect(() => {
    getFunnelsCb();
  }, [getFunnelsCb]);

  //  ---------- Render Functions

  const renderTableHead = (name = "Name", isCampaign) => {
    const cols = [
      { data: "Lead Count", width: 100 },
      { data: "Spend", width: 100 },
      { data: "Cost per Click", width: 100 },
      { data: "Cost per Lead", width: 100 },
      { data: "Cost per Qualified Lead", width: 100 },
      { data: "Qualified Lead Count", width: 100 },
      { data: "Clicks", width: 100 },
      { data: "Unique Clicks", width: 100 },
      { data: "Reach", width: 100 },
      { data: "Impressions", width: 100 }
      // "Start - End Date"
    ];

    if (isCampaign) {
      cols.unshift({ data: "Category", width: 180 });
      cols.unshift({ data: "Customer", width: 200 });
      cols.push({ data: "Funnel", width: 150 });
    }
    cols.unshift({ data: name, width: 200 });
    return (
      <RowH className="align-items-center py-3">
        <FirstColumn />
        {cols.length &&
          cols.map((col, idx) => (
            <ColumnF
              // lg={1}
              key={idx}
              width={col.width}
              className="text-break"
            >
              {col.data}
            </ColumnF>
          ))}
      </RowH>
    );
  };

  const renderTableData = (cols, rowIdx, key, showMore, cbFun) => {
    return (
      <>
        {showMore ? (
          // <Col xs={1}>
          <FirstColumn>
            <FontAwesomeIcon
              className="px-3 w-10 py-2 cursor-pointer"
              icon={activeRow[key] === rowIdx ? faChevronDown : faChevronRight}
              onClick={() =>
                fetchDataOnClick(
                  key,
                  rowIdx,
                  activeRow[key] !== rowIdx ? cbFun : null
                )
              }
            />
          </FirstColumn>
        ) : (
          // </Col>
          <FirstColumn />
        )}
        {cols.length &&
          cols.map((col, idx) => (
            <ColumnF
              // lg={1}
              key={idx}
              width={col.width}
              className="text-break"
            >
              {col.data}
            </ColumnF>
          ))}
      </>
    );
  };

  return (
    <div className="mt-3 w-100">
      <>
        <AdvancedFilters
          hideAddNew
          pageType="fbAds"
          pageName="campaignName"
          components={FEFilterConfig}
        />

        <div className="mt-3" />
        <CustomCard className="mb-3 p-3 rounded-0">
          <Card.Header className="bg-white mb-4">
            <h5>{t("funnelDetailsOverview")}</h5>
          </Card.Header>
          {isLoading.campaign ? (
            <Loader colSpan={8} />
          ) : !campaignEvalData?.length ? (
            <h6 className="mb-0 fw-bold p-5 align-self-center">
              {t("noResults")}...
            </h6>
          ) : (
            <div className="container-scroll">
              {/* Campaign Row */}
              <div style={{ maxWidth: "100%", overflowX: "scroll" }}>
                <>
                  {renderTableHead("Campaign Name", true)}
                  {campaignEvalData.map((campaign, camIdx) => (
                    <ParentRow key={campaign.campaign_id}>
                      <RowF
                        // key={campaign.campaign_id}
                        even={camIdx % 2 !== 0}
                        bold={Number(
                          activeRow["campaign"] === campaign.campaign_id
                        )}
                        className="align-items-center py-2"
                      >
                        {renderTableData(
                          transformData(campaign, campaign.campaign_name, true),
                          campaign.campaign_id,
                          "campaign",
                          true,
                          getAdsetsEvaluation
                        )}
                      </RowF>
                      <Collapse
                        timeout={500}
                        in={activeRow.campaign === campaign.campaign_id}
                      >
                        {/* Ad Set Row */}
                        <RowWrapper
                          even={camIdx % 2 !== 0}
                          bold={Number(
                            activeRow["campaign"] === campaign.campaign_id
                          )}
                        >
                          {isLoading.adset ? (
                            <div className="d-flex justify-content-center m-2">
                              <Spinner className="ms-3" animation="border" />
                            </div>
                          ) : !adsetEvalData?.length ? (
                            <h6 className="mb-0 fw-bold p-5 d-flex justify-content-center">
                              Not Enough Data to show / Ads may have Archived
                            </h6>
                          ) : (
                            <>
                              {adsetEvalData && renderTableHead("Adset Name")}
                              {adsetEvalData &&
                                adsetEvalData.map((adset, adsIdx) => (
                                  <ParentRow key={adsIdx}>
                                    <RowF
                                      even={adsIdx % 2 !== 0}
                                      bold={Number(
                                        activeRow["adset"] === adset.adset_id
                                      )}
                                      className="align-items-center py-2"
                                    >
                                      {renderTableData(
                                        transformData(adset, adset.adset_name),
                                        adset.adset_id,
                                        "adset",
                                        true,
                                        getAdsEvaluation
                                      )}
                                    </RowF>
                                    <Collapse
                                      timeout={500}
                                      in={activeRow.adset === adset.adset_id}
                                    >
                                      <RowWrapper
                                        even={adsIdx % 2 !== 0}
                                        bold={Number(
                                          activeRow["adset"] === adset.adset_id
                                        )}
                                      >
                                        {isLoading.ad ? (
                                          <div className="d-flex justify-content-center m-2">
                                            <Spinner
                                              className="ms-3"
                                              animation="border"
                                            />
                                          </div>
                                        ) : !adEvalData?.length ? (
                                          <h6 className="mb-0 fw-bold p-5 d-flex justify-content-center">
                                            Not Enough Data to show / Ads may
                                            have Archived
                                          </h6>
                                        ) : (
                                          <>
                                            {adEvalData &&
                                              renderTableHead("Ad Name")}
                                            {/* Ad Row */}
                                            {adEvalData &&
                                              adEvalData.map((ad, adIdx) => (
                                                <RowF
                                                  key={adIdx}
                                                  even={adIdx % 2 !== 0}
                                                  bold={Number(
                                                    activeRow["ad"] === ad.ad_id
                                                  )}
                                                  className="align-items-center py-2"
                                                >
                                                  {renderTableData(
                                                    transformData(
                                                      ad,
                                                      ad.ad_name
                                                    ),
                                                    ad.ad_id
                                                  )}
                                                </RowF>
                                              ))}
                                          </>
                                        )}
                                      </RowWrapper>
                                    </Collapse>
                                  </ParentRow>
                                ))}
                            </>
                          )}
                        </RowWrapper>
                      </Collapse>
                    </ParentRow>
                  ))}
                </>
              </div>
            </div>
          )}
        </CustomCard>
        {paginateOpts.totalPages && (
          <CustomPagination
            paginateOpts={paginateOpts}
            activeTab={Number(searchParams.get("pageIndex")) || 1}
            select={(val) => handleQueryParams("pageIndex", val)}
          />
        )}
      </>
      <FunnelLinksDialog
        onHide={toggleModal}
        show={showFunnelsModal}
        collection={campaignFunnels}
      />
    </div>
  );
};

export default EvaluationOverview;
