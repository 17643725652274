import { useEffect, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CustomCard,
  PageHeading,
  BootstrapTable,
  LastHeaderColumn,
  FirstHeaderColumn
} from "../common-styling";
import Filter from "../table-filter/filter";
import TableLoader from "../loader/table-loader";
import CategoriesTableRow from "./category-table-row";
import { showError } from "../../services/toast-service";
import CustomPagination from "../pagination/custom-pagination";
import { getAllCategories } from "../../services/api-declaration";
import { useTranslation } from "react-i18next";

const CategoriesTable = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpt] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      const response = await getAllCategories(params);
      const data = response.data.data;
      setCategories(data.rows);
      setPaginateOpt({
        totalPages: data.totalPages,
        hasPrevPage: data.hasPrevPage,
        hasNextPage: data.hasNextPage,
        totalDocs: data.totalDocs,
        rowsOnPage: data.rows.length
      });
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", {
            what: t("common.category", { count: 2 })
          })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const customersCb = useCallback(
    fetchCategories,
    // eslint-disable-next-line
    [searchParams]
  );

  useEffect(() => {
    customersCb();
  }, [customersCb]);
  const navigate = useNavigate();
  const handleModal = () => {
    navigate("new");
  };

  return (
    <div>
      <PageHeading className="">{t("common.category_other")}</PageHeading>
      <div className="mt-3">
        <Filter
          pageName="name"
          placeholder={t("searchByNamePlaceholder")}
          btnText={t("common.createNew.title", {
            name: t("common.category").toLowerCase()
          })}
          handleModal={handleModal}
        />
      </div>
      <CustomCard className="mt-4 mb-3 p-3 rounded-0">
        <BootstrapTable responsive>
          <thead>
            <tr>
              <FirstHeaderColumn>
                {t("common.category")} {t("common.name")}
              </FirstHeaderColumn>
              <LastHeaderColumn empty></LastHeaderColumn>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoader colSpan={2} />
            ) : (
              <CategoriesTableRow
                categories={categories}
                fetchCategory={fetchCategories}
              />
            )}
          </tbody>
        </BootstrapTable>
      </CustomCard>
      <CustomPagination
        paginateOpts={paginateOpts}
        activeTab={Number(searchParams.get("pageIndex")) || 1}
        select={(val) => handleQueryParams("pageIndex", val)}
      />
    </div>
  );
};
export default CategoriesTable;
