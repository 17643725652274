import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { fetchBlockedPostalCodesCount } from "../../services/api-declaration";
import { CustomCard } from "../common-styling";
const Customcard = styled(CustomCard)`
  margin-top: 4px;
  &:hover {
    background: #dff0ff;
  }
`;
const BlockedPostalCodeCount = ({ funnelId, toggleDrawer }) => {
  const [blockedPostalCodeCount, setblockedPostalCodeCount] = useState(0);
  const { t } = useTranslation();

  const getBlockedPostalCodesCount = async (funnelId) => {
    try {
      const { count } = await fetchBlockedPostalCodesCount(funnelId);
      setblockedPostalCodeCount(count);
    } catch (error) {
      console.log(error);
    }
  };
  const getBlockedPostalCodesCountCb = useCallback(
    (funnelId) => getBlockedPostalCodesCount(funnelId),
    []
  );
  useEffect(() => {
    getBlockedPostalCodesCountCb(funnelId);
  }, [getBlockedPostalCodesCountCb, funnelId]);

  return (
    <Customcard>
      <div
        onClick={() => blockedPostalCodeCount && toggleDrawer()}
        className="p-2 fw-bold  cursor-pointer d-flex flex-row"
      >
        <span className="text-secondary"> {t("common.blockedPostalCode")}</span>
        <span className="text-danger cursor-pointer ms-1">
          {blockedPostalCodeCount}
        </span>
      </div>
    </Customcard>
  );
};
export default BlockedPostalCodeCount;
