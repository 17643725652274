import { Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Loader from "../../loader/loader";
import { CustomCard } from "../../common-styling";
import { showError } from "../../../services/toast-service";
import AdvancedFilters from "../../table-filter/advanced-filter";
import CustomPagination from "../../pagination/custom-pagination";
import { PerformanceCard } from "../../customers/performance-card";
import { FUNNEL_PERFORMANCE_DURATION_FILTER } from "../../strings";
import { getFunnelsReport } from "../../../services/api-declaration";
import { FunnelOverviewFilterConfig } from "../../funnels-table/filter-config";
import { useTranslation } from "react-i18next";

const FunnelPerformanceOverview = () => {
  const { t } = useTranslation();
  const { FORTNIGHT } = FUNNEL_PERFORMANCE_DURATION_FILTER;
  const [searchParams, setSearchParams] = useSearchParams({
    dateRange: FORTNIGHT
  });
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpts] = useState({});

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const fetchFunnelsPerformance = useCallback(async () => {
    setIsLoading(true);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    try {
      const response = await getFunnelsReport(params);
      const respData = response.data.data;
      const meta = respData?.metadata;
      setReports(respData.rows);
      setPaginateOpts({
        totalPages: meta?.totalPages,
        hasPrevPage: meta?.hasPrevPage,
        hasNextPage: meta?.hasNextPage,
        totalDocs: meta?.total,
        rowsOnPage: respData.rows?.length
      });
    } catch (error) {
      console.log("Error in getting funnels performance: ", error);
      showError(
        error.response?.data?.message || "Error in getting funnels performance"
      );
    } finally {
      setIsLoading(false);
    }
  }, [searchParams]);
  useEffect(() => {
    fetchFunnelsPerformance();
  }, [fetchFunnelsPerformance]);

  return (
    <div className="my-4">
      <AdvancedFilters
        hideAddNew
        hideSearchFilter
        pageType="funnelOverview"
        components={FunnelOverviewFilterConfig}
      />
      <CustomCard className="my-3 p-3 rounded-0">
        <Card.Header className="bg-white mb-3 sticky-top">
          <h5>Funnel {t("overview")}</h5>
        </Card.Header>
        {isLoading ? (
          <Loader colSpan={8} />
        ) : reports.length === 0 ? (
          <CustomCard className="mb-3 p-3 rounded-0">
            <h6 className="mb-0 fw-bold p-5 align-self-center">
              {t("noData")}
            </h6>
          </CustomCard>
        ) : (
          reports.map((report, idx) => (
            <PerformanceCard
              key={`report-${idx}`}
              report={report}
              fromFunnelOverview
            />
          ))
        )}
      </CustomCard>
      {reports.length ? (
        <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        />
      ) : null}
    </div>
  );
};
export default FunnelPerformanceOverview;
