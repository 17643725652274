import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router";
import { Row, Col, Form, Container, Spinner, Collapse } from "react-bootstrap";
import { FieldSet } from "../common-styling";
import { INTEGRATION_TYPE } from "../strings";
import { useDialog } from "../../hooks/useDialog";
import {
  integrateFunnel,
  updateIntegration
} from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import { Trans, useTranslation } from "react-i18next";
import LeadServiceActionButton from "./integration-view/lead-service-action-button";
import { faCheck, faClose, faEdit } from "@fortawesome/free-solid-svg-icons";

const SlackNotifications = ({ slackIntegrations = [], setIntegrations }) => {
  const { t } = useTranslation();
  const id = useParams().id;
  const { funnelType, funnel } = useOutletContext();
  const { showConfirmationDialogBox } = useDialog();
  const [editMode, setEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaultSlackMessage =
    slackIntegrations.length && slackIntegrations[0]?.customMessage
      ? slackIntegrations[0]?.customMessage
      : `Neuer ${funnel?.category?.name} Lead für ${funnel?.customer?.firstName} ${funnel?.customer?.lastName}`;
  const methods = useForm({
    defaultValues: {
      enabled:
        slackIntegrations.length && slackIntegrations[0].active ? true : false,
      slackMessage: defaultSlackMessage
    }
  });
  const {
    watch,
    setValue,
    getValues,
    handleSubmit,
    register,
    formState: { errors, dirtyFields }
  } = methods;

  const submitHandler = async (formVals) => {
    try {
      setIsSubmitting(true);
      const body = {
        funnelType,
        integrationType: INTEGRATION_TYPE.SLACK,
        active: formVals.enabled,
        customMessage: formVals.slackMessage
      };
      const response = await integrateFunnel(id, body);
      showSuccess(response.data.message);
      setIntegrations((data) => {
        if (data.slack?.length) {
          data.slack[0] = {
            ...data.slack[0],
            active: formVals.enabled,
            customMessage: formVals.slackMessage,
            _id: response.data.data.upsertedId
          };
        } else {
          data.slack = [
            {
              funnelId: id,
              type: body.integrationType,
              active: formVals.enabled,
              customMessage: formVals.slackMessage,
              _id: response.data.data.upsertedId
            }
          ];
        }
        return { ...data };
      });
    } catch (error) {
      console.log("Error: ", error);
      showError(t("funnelIntegration.slack.toastError"));
    } finally {
      setIsSubmitting(false);
    }
  };
  const updateHandler = async (formVals) => {
    try {
      setIsSubmitting(true);
      const body = {
        funnelType,
        integrationType: INTEGRATION_TYPE.SLACK,
        active: formVals.enabled,
        customMessage: formVals.slackMessage
      };
      const response = await updateIntegration(slackIntegrations[0]?._id, body);
      showSuccess(response.data.message);
      setIntegrations((data) => {
        data.slack[0] = {
          ...data.slack[0],
          active: formVals.enabled,
          customMessage: formVals.slackMessage
        };
        return { ...data };
      });
    } catch (error) {
      console.log("Error: ", error);
      showError(t("funnelIntegration.slack.toastError"));
    } finally {
      setIsSubmitting(false);
      if (editMode) {
        setEditMode(false);
      }
    }
  };

  const handleCheckboxChange = (_checked) => {
    toggleConfirmationDialog(_checked);
  };
  const toggleConfirmationDialog = (_checked) => {
    showConfirmationDialogBox({
      title: "Warning",
      dialogType: "warning",
      dialogMessage: renderDialogMessage(_checked),
      responseAction: (action) => dialogResponse(action, _checked)
    });
  };
  const renderDialogMessage = (_checked) => {
    const content = _checked
      ? t("funnelIntegration.enableDialogMsg")
      : t("funnelIntegration.disableDialogMsg");
    return <Trans i18nKey={content} />;
  };
  const dialogResponse = (action, _checked) => {
    if (action) {
      setValue("enabled", _checked);
      if (_checked) {
        submitHandler(getValues());
      } else {
        updateHandler(getValues());
      }
    }
  };

  const handleDiscardChanges = () => {
    setValue("slackMessage", defaultSlackMessage);
    setEditMode(false);
  };

  return (
    <div className="px-3">
      <FieldSet>
        <Container fluid className="px-0">
          <Row>
            <Col xs={12}>
              <FormProvider {...methods}>
                <Form className="p-0" onSubmit={handleSubmit(submitHandler)}>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Check
                      id="slack"
                      type="checkbox"
                      label={t("funnelIntegration.slack.checkboxLabel")}
                      checked={watch("enabled")}
                      disabled={editMode}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(checked)
                      }
                    />
                    {isSubmitting && <Spinner animation="border" size="sm" />}
                  </div>
                </Form>
              </FormProvider>
            </Col>
          </Row>
          <Collapse in={watch("enabled")}>
            <Row className="align-items-start mt-2">
              <Col sm={12}>
                <Form.Label
                  className={`mb-2 text-break fw-bold ${
                    errors?.slackMessage ? "text-danger" : ""
                  }`}
                >
                  {`${t("common.message")}:`}
                </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  size="sm"
                  as={"textarea"}
                  className={`${errors?.slackMessage ? "border-danger" : ""}`}
                  placeholder={`${t("common.type")} ${t("common.message")}`}
                  {...register("slackMessage")}
                  defaultValue={defaultSlackMessage}
                  disabled={!editMode}
                />
              </Col>
              <Col sm={2}>
                {!editMode ? (
                  <LeadServiceActionButton
                    faIcon={faEdit}
                    btnVariant="outline-primary"
                    tooltipMsg={t("common.edit")}
                    clickHandler={() => setEditMode(true)}
                  />
                ) : (
                  <div className="d-flex gap-2">
                    <LeadServiceActionButton
                      faIcon={faCheck}
                      isLoading={isSubmitting}
                      btnVariant="outline-success"
                      tooltipMsg={t("common.save")}
                      clickHandler={() => updateHandler(getValues())}
                      isDisabled={!dirtyFields?.slackMessage}
                    />
                    <LeadServiceActionButton
                      faIcon={faClose}
                      btnVariant="outline-danger"
                      tooltipMsg={t("common.cancel")}
                      clickHandler={handleDiscardChanges}
                      isDisabled={isSubmitting}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Collapse>
        </Container>
      </FieldSet>
    </div>
  );
};

export default SlackNotifications;
