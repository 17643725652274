import { useEffect, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CustomCard,
  PageHeading,
  BootstrapTable,
  LastHeaderColumn,
  FirstHeaderColumn
} from "../common-styling";
import Filter from "../table-filter/filter";
import TagsTableRow from "./tags-table-row";
import TableLoader from "../loader/table-loader";
import { showError } from "../../services/toast-service";
import { getAllTags } from "../../services/api-declaration";
import CustomPagination from "../pagination/custom-pagination";
import { useTranslation } from "react-i18next";

const TagsTable = () => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpt] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchTags = async () => {
    try {
      setIsLoading(true);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      const response = await getAllTags(params);
      const data = response.data.data;
      setTags(data.rows);
      setPaginateOpt({
        totalPages: data.totalPages,
        hasPrevPage: data.hasPrevPage,
        hasNextPage: data.hasNextPage,
        totalDocs: data.totalDocs,
        rowsOnPage: data.rows.length
      });
    } catch (error) {
      console.log(error);
      showError(error.response.data.message || "Error in loading tags!");
    } finally {
      setIsLoading(false);
    }
  };
  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const customersCb = useCallback(fetchTags, [searchParams]);

  useEffect(() => {
    customersCb();
  }, [customersCb]);
  const navigate = useNavigate();
  const handleModal = () => {
    navigate("new");
  };
  return (
    <div>
      <PageHeading className="">{t("sidebar.tags")}</PageHeading>
      <div className="mt-3">
        <Filter
          pageName="name"
          placeholder={t("searchByNamePlaceholder")}
          btnText={t("common.createNew.title", {
            name: t("sidebar.tags").toLowerCase()
          })}
          shouldDisplayResourceType={false}
          handleModal={handleModal}
        />
      </div>
      <CustomCard className="mt-4 mb-3 p-3 rounded-0">
        <BootstrapTable responsive>
          <thead>
            <tr>
              <FirstHeaderColumn>Tag Name</FirstHeaderColumn>
              <LastHeaderColumn empty></LastHeaderColumn>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoader colSpan={2} />
            ) : (
              <TagsTableRow tags={tags} />
            )}
          </tbody>
        </BootstrapTable>
      </CustomCard>
      <CustomPagination
        paginateOpts={paginateOpts}
        activeTab={Number(searchParams.get("pageIndex")) || 1}
        select={(val) => handleQueryParams("pageIndex", val)}
      />
    </div>
  );
};
export default TagsTable;
